<template>
  <div style="height: 100%;">
    <iframe id="myIframe" :src="url" frameborder="0">
      <link rel="stylesheet" type="text/css" href="print.css" media="print">
    </iframe>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      reportId: '',
      url: ''
    };
  },
  created() {
    this.reportId = this.$route.query.id
    this.url = 'https://www.apesk.com/mensa/common_report_getid/holland2_report_getid.asp?id=' + this.reportId
    const head = document.getElementById("footer");
    head.style.display = "none";
    const LuHead = document.getElementById("LuHead");
    LuHead.style.display = "none";
  },
  watch: {
  },
  methods: {
    test() {
      document.getElementById('myIframe').contentWindow.print();
    }
  },
};
</script>

<style scoped lang='less'>
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
